import React from "react";
import "./Tesla.css";
import Nav from "./Nav";
import ColorPicker from "./TarotComponents/colorPicker";
//import Orbit from "./TarotComponents/Orbit";
import Bulb from "./TarotComponents/Bulb";
//import Floor from "./TarotComponents/Floor";
import Background from "./TarotComponents/Background";
import Dragable from "./TarotComponents/Dragable";
import TarotCard from "./TarotComponents/TarotCard"; // Import your TarotCard component
import allCards from "./TarotComponents/AllCards"; // Adjust the import path based on your file structure

import { Suspense } from "react";
import { Canvas } from "@react-three/fiber";

function Tarot() {
  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <Nav />
      <ColorPicker />
      <Canvas
        shadows // Enable shadows
        style={{ background: "black" }}
        camera={{ position: [0, 10, 20] }}
      >
        {/* <fog attach="fog" args={["white", 10, 2]} /> */}
        <ambientLight intensity={0.2} />
        {/* <Orbit /> */}
        <axesHelper args={[5]} />
        <Dragable>
          <Suspense fallback={null}>
            {/* Replace the Box component with your TarotCard */}
            {allCards.map((cardUrl, index) => (
              <TarotCard
                key={index}
                position={[index/5, 0, index/10]}
                scale={1.5}
                aCard={cardUrl}
              />
            ))}
          </Suspense>
        </Dragable>
        <Suspense fallback={null}>
          <Background />
        </Suspense>
        <Bulb position={[0, 3, 0]} />
      </Canvas>
    </div>
  );
}

export default Tarot;
