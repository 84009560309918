import React from "react";
import "./Tesla.css";
import Nav from "./Nav";

import { useRef, Suspense } from "react";
import * as THREE from "three";
import {
  Canvas,
  useFrame,
  useThree,
  extend,
  useLoader,
} from "@react-three/fiber";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
//import { Mesh, BoxGeometry, MeshStandardMaterial } from "three";

extend({ OrbitControls }); //not sure the difference between
//extend({ OrbitControls, Mesh, BoxGeometry, MeshStandardMaterial });

const Orbit = () => {
  const { camera, gl } = useThree();
  return <orbitControls args={[camera, gl.domElement]} />;
};

const Bulb = (props) => {
  return (
    <mesh {...props}>
      <pointLight castShadow />
      <sphereGeometry args={[0.2, 20, 20]} />
      <meshPhongMaterial emissive="yellow" />
    </mesh>
  );
};

const Box = (props) => {
  const ref = useRef();
  const texture = useLoader(
    THREE.TextureLoader,
    "/assets/slate_driveway_diff_1k.jpg"
  );
  useFrame(() => {
    ref.current.rotation.x += 0.01;
    ref.current.rotation.y += 0.01;
  });
  return (
    <mesh ref={ref} {...props} castShadow receiveShadow>
      <boxGeometry />
      <meshPhysicalMaterial
        map={texture}
        //color="orange"
        fog={false}
        //opacity={0.7}
        //transparent //needed for opacity to work
        //visible={true /*default is true*/}
        //wireframe
        //metalness={1}
        //roughness={0} //can now see the light on the box
        //clearcoat={1}
        //transmission={0.7} //opacity must =1 and transparent=true
        //reflectivity={1}
        //side={THREE.DoubleSide}
      />
    </mesh>
  );
};

const Floor = (props) => {
  return (
    <mesh {...props} receiveShadow>
      <boxGeometry args={[4, .1, 4]} />
      <meshPhysicalMaterial color="grey" />
    </mesh>
  );
};

const Background = props => {
  const texture = useLoader(
    THREE.TextureLoader,
    "/assets/universeppt.jpg"
  );

return (
  <primitive
  attach='background'
  object={texture}
  />
)
}

function Tesla() {
  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <Nav />
      <Canvas
        shadows // Enable shadows
        style={{ background: "black" }}
        camera={{ position: [3, 3, 3] }}
      >
        <fog attach="fog" args={["white", 10, 2]} />
        <ambientLight intensity={0.2} />
        <Orbit />
        <axesHelper args={[5]} />
        <Suspense fallback={null}>
          <Box position={[0, 1, 0]} />
        </Suspense>
        <Suspense fallback={null}>
          <Background/>
        </Suspense>
        <Bulb position={[0, 3, 0]} />
        <Floor position={[0, -0.5, 0]} />
        <points>
          <vector3 attachArray="vertices" />
          <vector3 args={[0, 1, 1]} attachArray="vertices" />
          <vector3 args={[0, 1, 1]} attachArray="vertices" />
        </points>
      </Canvas>
    </div>
  );
}

export default Tesla;
