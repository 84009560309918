import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Nav from '../Nav';
//import { use } from './../server/routes/post';


const SinglePost = (props) => {
    const [post, setPost] = useState('');

    useEffect(() => { 
        console.log(`${process.env.REACT_APP_API}/post/${props.match.params.slug}`);
        axios.get(`${process.env.REACT_APP_API}/post/${props.match.params.slug}`)
            .then(response => {
                console.log("here");
                console.log("the response data the post " + response.data);
                setPost(response.data);
            })
            .catch(error => {
                console.log(props.match.params.slug);
                alert('Error loading single post');
            });
             
    }, []);

    return (
        <div className="container pb-5">
            <Nav />
            <br />
            <h1>{post.title}</h1>
            <p className="lead">{post.content}</p>
                        <p>
                Author <span className="badge bg-secondary">{post.user}</span> Published on{' '}
                <span className="badge bg-secondary">{new Date(post.createdAt).toLocaleString()}</span>
                        </p>
        </div>
    );
};

export default SinglePost;
