/*
import React from 'react';
import ReactDOM from 'react-dom/client';

import { App } from './App.js'

ReactDOM.createRoot( 
  document.querySelector('#root')
).render(<App />)
*/


//was using for surveys etc
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Routes from './Routes/Routes';

ReactDOM.render(<Routes />, document.getElementById('root'));



/*
//For 3D
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Tesla from "./Teslav1";
//import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Tesla />
  </React.StrictMode>
);
*/
/*
//3d removing strict mode  must understand root.render
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Tesla from "./Teslav1";
//import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Tesla />
);
*/
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

//error for next line so commenting out
//reportWebVitals();
