export const json = {
    "logoPosition": "right",
    "pages": [
     {
      "name": "page1",
      "elements": [
       {
        "type": "text",
        "name": "Who",
        "title": "Email - Username",
        "isRequired": true
       },
       {
        "type": "matrixdropdown",
        "name": "question1",
        "title": "Supplements of the day",
        "isRequired": true,
        "columns": [
         {
          "name": "Column 1",
          "title": "Took and # pills",
          "cellType": "dropdown",
          "choices": [
           {
            "value": 1,
            "text": "0"
           },
           {
            "value": 2,
            "text": "1"
           },
           {
            "value": 3,
            "text": "2"
           },
           {
            "value": 4,
            "text": "3"
           },
           {
            "value": 5,
            "text": "4"
           }
          ],
          "storeOthersAsComment": true
         },
         {
          "name": "Column 2",
          "title": "Time(s) of day",
          "cellType": "dropdown",
          "choices": [
           {
            "value": 1,
            "text": "morning"
           },
           {
            "value": 2,
            "text": "afternoon"
           },
           {
            "value": 3,
            "text": "evening"
           },
           {
            "value": 4,
            "text": "morning and evening"
           },
           {
            "value": 5,
            "text": "morning and afternoon"
           },
           {
            "value": 6,
            "text": "every meal"
           }
          ],
          "storeOthersAsComment": true
         },
         {
          "name": "Comments",
          "title": "Comments",
          "cellType": "text"
         }
        ],
        "choices": [
         1,
         2,
         3,
         4,
         5
        ],
        "rows": [
         {
          "value": "Row 1",
          "text": "Multivitamin"
         },
         {
          "value": "Row 2",
          "text": "B1"
         },
         {
          "value": "Row 3",
          "text": "B50"
         },
         {
          "value": "Row 4",
          "text": "B mix"
         },
         {
          "value": "Row 5",
          "text": "Pregnenolone"
         },
         {
          "value": "Row 6",
          "text": "Ashwaganda"
         },
         {
          "value": "Row 7",
          "text": "BCAAs"
         },
         {
          "value": "Row 8",
          "text": "Plant Protein Powder"
         },
         {
          "value": "Row 9",
          "text": "Whey"
         },
         {
          "value": "Row 10",
          "text": "Boron"
         },
         {
          "value": "Row 11",
          "text": "Magnesium"
         },
         {
          "value": "Row 12",
          "text": "Vitamin C powder"
         },
         {
          "value": "Row 13",
          "text": "Calcium"
         },
         {
          "value": "Row 14",
          "text": "Copper"
         },
         {
          "value": "Row 15",
          "text": "Zinc"
         },
         {
          "value": "Row 16",
          "text": "Glutamine"
         }
        ]
       },
       {
        "type": "rating",
        "name": "question2",
        "title": "Sleep Score 1 sucks 3 is 60s 5 great 90+",
        "isRequired": true,
        "rateType": "stars"
       },
       {
        "type": "rating",
        "name": "question3",
        "title": "Readiness Score 1 sucks 3 is 60s 5 great 90+",
        "isRequired": true,
        "rateType": "stars"
       }
      ]
     }
    ]
   }