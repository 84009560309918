import React, { useState } from "react";
import * as THREE from "three";
import { useFrame } from "@react-three/fiber";
import { TextureLoader } from "three";

const textureLoader = new TextureLoader();

const TarotCard = (props) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const cardHeight = 5;
  const cardWidth = 3;
  const cardGeometry = new THREE.PlaneGeometry(cardWidth, cardHeight);

  const cardFrontMaterial = new THREE.MeshPhongMaterial({
    map: textureLoader.load("/assets/TarotCards/back.jpg"), 
  });
  const cardBackMaterial = new THREE.MeshBasicMaterial({
    map: textureLoader.load(props.aCard), // Use the passed prop
  });

  const card = new THREE.Mesh(cardGeometry, cardFrontMaterial);

  card.position.set(0, 0, 0);
  card.rotation.set(0, Math.PI, 0); // Rotate the card 180 degrees for the back

  const handleDoubleCardClick = () => {
    setIsFlipped(!isFlipped);
    card.material = isFlipped ? cardFrontMaterial : cardBackMaterial;
  };

  return (
    <mesh onDoubleClick={handleDoubleCardClick} {...props}>
      <planeGeometry args={[cardWidth, cardHeight]} />
      <meshPhongMaterial
        map={isFlipped ? cardBackMaterial.map : cardFrontMaterial.map}
      />
    </mesh>
  );
};

export default TarotCard;
