import React from "react";
import Nav from "../Nav";
import SurveyComponent from "./SurveyComponent";

export function SurveyContainer(props) {
  return (
    <div className="App">
    <Nav />
      <SurveyComponent />
    </div>
  );
}

export default SurveyContainer;