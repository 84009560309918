const wandCards=[
  '/assets/TarotCards/Wands/Wands_2.jpg',
  '/assets/TarotCards/Wands/Wands_3.jpg',
  '/assets/TarotCards/Wands/Wands_4.jpg',
  '/assets/TarotCards/Wands/Wands_5.jpg',
  '/assets/TarotCards/Wands/Wands_6.jpg',
  '/assets/TarotCards/Wands/Wands_7.jpg',
  '/assets/TarotCards/Wands/Wands_8.jpg',
  '/assets/TarotCards/Wands/Wands_9.jpg',
  '/assets/TarotCards/Wands/Wands_10.jpg',
  '/assets/TarotCards/Wands/Wands_Ace.jpg',
  '/assets/TarotCards/Wands/Wands_King.jpg',
  '/assets/TarotCards/Wands/Wands_Knight.jpg',
  '/assets/TarotCards/Wands/Wands_Page.jpg',
  '/assets/TarotCards/Wands/Wands_Queen.jpg'
]

const swordCards=[
  '/assets/TarotCards/Swords/Swords_2.jpg',
  '/assets/TarotCards/Swords/Swords_3.jpg',
  '/assets/TarotCards/Swords/Swords_4.jpg',
  '/assets/TarotCards/Swords/Swords_5.jpg',
  '/assets/TarotCards/Swords/Swords_6.jpg',
  '/assets/TarotCards/Swords/Swords_7.jpg',
  '/assets/TarotCards/Swords/Swords_8.jpg',
  '/assets/TarotCards/Swords/Swords_9.jpg',
  '/assets/TarotCards/Swords/Swords_10.jpg',
  '/assets/TarotCards/Swords/Swords_Ace.jpg',
  '/assets/TarotCards/Swords/Swords_King.jpg',
  '/assets/TarotCards/Swords/Swords_Knight.jpg',
  '/assets/TarotCards/Swords/Swords_Page.jpg',
  '/assets/TarotCards/Swords/Swords_Queen.jpg'
]

const pentacleCards=[
  '/assets/TarotCards/Pentacles/Pentacles_2.jpg',
  '/assets/TarotCards/Pentacles/Pentacles_3.jpg',
  '/assets/TarotCards/Pentacles/Pentacles_4.jpg',
  '/assets/TarotCards/Pentacles/Pentacles_5.jpg',
  '/assets/TarotCards/Pentacles/Pentacles_6.jpg',
  '/assets/TarotCards/Pentacles/Pentacles_7.jpg',
  '/assets/TarotCards/Pentacles/Pentacles_8.jpg',
  '/assets/TarotCards/Pentacles/Pentacles_9.jpg',
  '/assets/TarotCards/Pentacles/Pentacles_10.jpg',
  '/assets/TarotCards/Pentacles/Pentacles_Ace.jpg',
  '/assets/TarotCards/Pentacles/Pentacles_King.jpg',
  '/assets/TarotCards/Pentacles/Pentacles_Knight.jpg',
  '/assets/TarotCards/Pentacles/Pentacles_Page.jpg',
  '/assets/TarotCards/Pentacles/Pentacles_Queen.jpg'
]

const cupsCards=[
  '/assets/TarotCards/Cups/Cups_2.jpg',
  '/assets/TarotCards/Cups/Cups_3.jpg',
  '/assets/TarotCards/Cups/Cups_4.jpg',
  '/assets/TarotCards/Cups/Cups_5.jpg',
  '/assets/TarotCards/Cups/Cups_6.jpg',
  '/assets/TarotCards/Cups/Cups_7.jpg',
  '/assets/TarotCards/Cups/Cups_8.jpg',
  '/assets/TarotCards/Cups/Cups_9.jpg',
  '/assets/TarotCards/Cups/Cups_10.jpg',
  '/assets/TarotCards/Cups/Cups_Ace.jpg',
  '/assets/TarotCards/Cups/Cups_King.jpg',
  '/assets/TarotCards/Cups/Cups_Knight.jpg',
  '/assets/TarotCards/Cups/Cups_Page.jpg',
  '/assets/TarotCards/Cups/Cups_Queen.jpg'
]

const greatArkanaCards=[
  '/assets/TarotCards/greatArkana/0_TheFool.jpg',
  '/assets/TarotCards/greatArkana/1_TheMagician.jpg',
  '/assets/TarotCards/greatArkana/2_TheHighPriestess.jpg',
  '/assets/TarotCards/greatArkana/3_TheEmpress.jpg',
  '/assets/TarotCards/greatArkana/4_TheEmperor.jpg',
  '/assets/TarotCards/greatArkana/5_TheHierophant.jpg',
  '/assets/TarotCards/greatArkana/6_TheLovers.jpg',
  '/assets/TarotCards/greatArkana/7_TheChariot.jpg',
  '/assets/TarotCards/greatArkana/8_Strength.jpg',
  '/assets/TarotCards/greatArkana/9_TheHermit.jpg',
  '/assets/TarotCards/greatArkana/10_WheelOfFortune.jpg',
  '/assets/TarotCards/greatArkana/11_Justice.jpg',
  '/assets/TarotCards/greatArkana/12_TheHangedMan.jpg',
   '/assets/TarotCards/greatArkana/13_Death.jpg',
  '/assets/TarotCards/greatArkana/14_Temperance.jpg',
  '/assets/TarotCards/greatArkana/15_TheDevil.jpg',
  '/assets/TarotCards/greatArkana/16_TheTower.jpg',
  '/assets/TarotCards/greatArkana/17_TheStar.jpg',
  '/assets/TarotCards/greatArkana/18_TheMoon.jpg',
  '/assets/TarotCards/greatArkana/19_TheSun.jpg',
  '/assets/TarotCards/greatArkana/20_Judgement.jpg',
  '/assets/TarotCards/greatArkana/21_TheWorld.jpg',
]


const allCards= [...cupsCards,...pentacleCards, ...swordCards, ...wandCards, ...greatArkanaCards ];

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  }
  
  shuffleArray(allCards);

export default allCards;