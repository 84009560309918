export const json = {
    "title": "Spreading Joy",
    "description": "Let's spread joy.",
    "logoPosition": "right",
    "pages": [
     {
      "name": "page1",
      "elements": [
       {
        "type": "text",
        "name": "question1",
        "title": "First Name",
        "isRequired": true
       },
       {
        "type": "text",
        "name": "question2",
        "title": "Email",
        "isRequired": true,
        "inputType": "email"
       },
       {
        "type": "text",
        "name": "question3",
        "title": "What brought you the most joy today?",
        "isRequired": true
       },
       {
        "type": "text",
        "name": "question4",
        "title": "What did you do to bring joy to others today?",
        "isRequired": true
       },
       {
        "type": "text",
        "name": "question5",
        "title": "How did you help someone today? Even if in just a small way",
        "isRequired": true
       }
      ],
      "description": "Joy Journal"
     }
    ],
    "questionTitlePattern": "requireNumTitle"
   }