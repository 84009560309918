import { DragControls } from "three/examples/jsm/controls/DragControls";
import { useRef, useEffect, useState } from "react";
import { extend, useThree } from "react-three-fiber";
extend({ DragControls });

const Dragable = (props) => {
  const groupRef = useRef();
  const controlsRef = useRef();
  const [children, setChildren] = useState([]);
  const { camera, gl, scene } = useThree();
  useEffect(() => {
    //console.log(groupRef.current);
    setChildren(groupRef.current.children);
  }, []);

  useEffect(() => {
    const handleHoverOn = () => {
      console.log(scene);
      scene.orbitControls.enabled = false;
    };

    const handleHoverOff = () => {
      console.log(scene);
      scene.orbitControls.enabled = true;
    };

    controlsRef.current.addEventListener("hoveron", handleHoverOn);
    controlsRef.current.addEventListener("hoveroff", handleHoverOff);

    return () => {
      controlsRef.current.removeEventListener("hoveron", handleHoverOn);
      controlsRef.current.removeEventListener("hoveroff", handleHoverOff);
    };
  }, [controlsRef, scene]);

  //console.log(props.children);
  return (
    <group ref={groupRef}>
      <dragControls
        ref={controlsRef}
        args={[children, camera, gl.domElement]}
      />
      {props.children}
    </group>
  );
};

export default Dragable;
