import React from "react";
import "./Tesla.css";
import Nav from "./Nav";
import ColorPicker from "./TeslaComponents/colorPicker"; //seems case sensitive needed capital Color
import Orbit from "./TeslaComponents/Orbit";
import Bulb from "./TeslaComponents/Bulb";
import Box from "./TeslaComponents/Box";
import Floor from "./TeslaComponents/Floor";
import Background from "./TeslaComponents/Background";
import Dragable from "./TeslaComponents/Dragable";

import { useRef, Suspense, useState } from "react";
import * as THREE from "three";
import {
  Canvas,
  useFrame,
  useThree,
  extend,
  useLoader,
} from "@react-three/fiber";

function Tesla() {
  return (
    <div style={{ height: "100vh", width: "100vw" }}>
      <Nav />
      <ColorPicker />
      <Canvas
        shadows // Enable shadows
        style={{ background: "black" }}
        camera={{ position: [7, 7, 7] }}
      >
        <fog attach="fog" args={["white", 10, 2]} />
        <ambientLight intensity={0.2} />
        <Orbit/>
        <axesHelper args={[5]} />
        <Dragable>
          <Suspense fallback={null}>
            <Box position={[-1, 1, 0]} />
          </Suspense>
          <Suspense fallback={null}>
            <Box position={[1, 1, 0]} />
          </Suspense>
        </Dragable>
        <Suspense fallback={null}>
          <Background />
        </Suspense>
        <Bulb position={[0, 3, 0]} />
        <Floor position={[0, -0.5, 0]} />
      </Canvas>
    </div>
  );
}

export default Tesla;
