import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import App from "../App";
import Create from "../CreatePost/Create";
import SinglePost from "../CreatePost/SinglePost";
import UpdatePost from "../CreatePost/UpdatePost";
import Login from "../CreatePost/Login";
import PrivateRoute from "./PrivateRoute";
import ListPosts from "../CreatePost/ListPosts";

import SurveyContainer from "../Surveys/SurveyContainer";
import Supplements from "../Surveys/Supplements";
import Tesla from "../Teslav1";
import HomeP from "../HomeP";
import Tarot from "../TarotReadings";

const Routes = () => {
  return (
    <div className="container pb-5">
      <BrowserRouter>
        <Switch>
        <Route path="/" exact component={HomeP} />
        <Route path="/tesla" exact component={Tesla} />
        <Route path="/tarot" exact component={Tarot} />
          <PrivateRoute path="/create" exact component={Create} />
          <Route path="/login" exact component={Login} />
          <Route path="/post/:slug" exact component={SinglePost} />
          <PrivateRoute
            path="/post/update/:slug"
            exact
            component={UpdatePost}
          />
          <Route path="/listposts" exact component={ListPosts} />
          <PrivateRoute path="/survey" exact component={SurveyContainer} />
          <Route path="/supplements" exact component={Supplements} />
        </Switch>
      </BrowserRouter>
    </div>
  );
};

export default Routes;
