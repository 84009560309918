import React from "react";
//import { Link, withRouter } from "react-router-dom";
//import { getUser, logout } from "./helpers";

const Nav = ({ history }) => (
  <div class="border border-danger">
    <nav class="navbar navbar-expand-lg bg-warning">
      <div class="container-fluid">
        <a class="navbar-brand" href="/">
          Home
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="/tesla">
                Tesla Shop
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="/tarot">
                Tarot Card Readings
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="/login">
                Login
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="/create">
                Create Post
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link active" aria-current="page" href="/listposts">
                List Posts
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/Games/index.html">
                Games
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/Drawing/index.html">
                Drawing
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/supplements">
                Supplements
              </a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="/survey">
                Survey
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
);

export default Nav;
