import React from "react";

import { useRef, Suspense, useState } from "react";
import * as THREE from "three";
import {
  Canvas,
  useFrame,
  useThree,
  extend,
  useLoader,
} from "@react-three/fiber";

const Box = (props) => {
  const ref = useRef();
  const [active, setActive] = useState(false);
  const texture = useLoader(
    THREE.TextureLoader,
    "/assets/slate_driveway_diff_1k.jpg"
  );
  useFrame(() => {
    ref.current.rotation.x += 0.01;
    ref.current.rotation.y += 0.01;
  });

  const handlePointerDown = (e) => {
    setActive(true); // Set the local state to active
    if (window.activeMesh && window.activeMesh !== e.object) {
      // Check if there's an active mesh and if it's different from the current one
      scaleDown(window.activeMesh);
      window.activeMesh.active = false; // Here, 'window.activeMesh' should be defined
    }
    window.activeMesh = e.object;
  };

  const handlePointerEnter = (e) => {
    e.object.scale.x = 1.5;
    e.object.scale.y = 1.5;
    e.object.scale.z = 1.5;
  };
  const handlePointerLeave = (e) => {
    if (!active) {
      scaleDown(e.object);
    }
  };

  const scaleDown = (object) => {
    object.scale.x = 1;
    object.scale.y = 1;
    object.scale.z = 1;
  };

  return (
    <mesh
      ref={ref}
      {...props}
      castShadow
      receiveShadow
      onPointerDown={handlePointerDown}
      onPointerEnter={handlePointerEnter}
      onPointerLeave={handlePointerLeave}
    >
      <boxGeometry />
      <meshPhysicalMaterial
        map={texture}
        //color="orange"
        fog={false}
        //opacity={0.7}
        //transparent //needed for opacity to work
        //visible={true /*default is true*/}
        //wireframe
        //metalness={1}
        //roughness={0} //can now see the light on the box
        //clearcoat={1}
        //transmission={0.7} //opacity must =1 and transparent=true
        //reflectivity={1}
        //side={THREE.DoubleSide}
      />
    </mesh>
  );
};

export default Box;