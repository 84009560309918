import React from "react";
import axios from "axios";
import { getToken } from "../helpers";


import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
//import "./index.css";
import { json } from "./json";

function SurveyComponent() {
  const survey = new Model(json);
  survey.onComplete.add(function (sender, options) {
    console.log("surveycomponent 1");
    console.log(sender.data);
    options.showSaveInProgress();
    axios
      .post(`${process.env.REACT_APP_API}/postJoy`, sender.data,
      {
          headers: { authorization: `Bearer ${getToken()}` }
      })
      .then((response) => {
        console.log(response);
        //console.log(sender.data);
        // empty state
        //setState({ ...state, title: '', user: '' });
        //setContent('');
        // show sucess alert
        alert(`Survey entered `);
        options.showSaveSuccess();
      })
      .catch((error) => {
        console.log(error.response.data);
        alert(JSON.stringify(sender.data));
        alert(error.response.data.error);
        options.showSaveError();
      });
  });
  return <Survey model={survey} />;
}

export default SurveyComponent;
