import React from "react";

const Floor = (props) => {
  return (
    <mesh {...props} receiveShadow>
      <boxGeometry args={[4, 0.1, 4]} />
      <meshPhysicalMaterial color="grey" />
    </mesh>
  );
};

export default Floor;