import React, { useState, useEffect } from "react";
import axios from "axios";
import Nav from "../Nav";
import { getToken } from "../helpers";

const UpdatePost = (props) => {
  const [state, setState] = useState({
    title: "",
    content: "",
    slug: "",
    user: "",
  });

  const { title, content, slug, user } = state;

  useEffect(() => {
    console.log(`${process.env.REACT_APP_API}/post/${props.match.params.slug}`);
    axios
      .get(`${process.env.REACT_APP_API}/post/${props.match.params.slug}`)
      .then((response) => {
        console.log("here");
        console.log("the response data the post " + response.data);
        const { title, content, slug, user } = response.data;
        setState({ ...state, title, content, slug, user });
      })
      .catch((error) => {
        console.log(props.match.params.slug);
        alert("Error updating post");
      });
  }, []);

  function handleChange(name) {
    return function (event) {
      setState({ ...state, [name]: event.target.value });
    };
  }

  const handleSubmit = (event) => {
    console.log(process.env.REACT_APP_API);
    event.preventDefault();
    console.table({ title, content, user });
    axios
      .put(
        `${process.env.REACT_APP_API}/post/${slug}`,
        {
          title,
          content,
          user,
        },
        {
          headers: { authorization: `Bearer ${getToken()}` },
        }
      )
      .then((response) => {
        //empty the state
        //show alert to the user success
        console.log(response);
        const { title, content, slug, user } = response.data;
        setState({ ...state, title, content, slug, user });
        alert(`Post titled ${title} is updated`);
      })
      .catch((error) => {
        console.log(error.response);
        alert(error.response.data.error);
      });
  };

  const showUpdateForm = () => (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label className="text-muted">Title</label>
        <input
          onChange={handleChange("title")}
          value={title}
          type="text"
          className="form-control"
          placeholder="post title"
          required
        />
      </div>
      <div className="form-group">
        <label className="text-muted">Content</label>
        <textarea
          onChange={handleChange("content")}
          value={content}
          type="text"
          className="form-control"
          placeholder="write something"
          required
        />
      </div>
      <div className="form-group">
        <label className="text-muted">User</label>
        <input
          onChange={handleChange("user")}
          value={user}
          type="text"
          className="form-control"
          placeholder="place holder username"
          required
        />
      </div>
      <div>
        <button className="btn btn-primary">Update</button>
      </div>
    </form>
  );

  return (
    <div className="container pb-5">
      <Nav />
      <br />
      <h1>Update Post</h1>
      {showUpdateForm()}
    </div>
  );
};

export default UpdatePost;
