import React from "react";
import Nav from "./Nav";
import SurveyComponent from "./Surveys/SurveyComponent";

export function App(props) {
  return (
    <div className="App">
    <Nav />
      <SurveyComponent />
    </div>
  );
}

// Log to console
console.log("Hello console");

export default App;

