import React from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
//import "./index.css";
import { json } from "./supplementsjson";
import Nav from "../Nav";


function Supplements() {
  const survey = new Model(json);
  survey.onComplete.add((sender, options) => {
    console.log(JSON.stringify(sender.data, null, 3));
  });
  return (
    <div>
      <Nav />
      <Survey model={survey} />
    </div>
  );
}

export default Supplements;
